import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Stack,
  Switch,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TagType } from "typings/tag";
import useQuery from "hooks/useQuery";
import FileUpload from "components/Core/Inputs/FileUpload";
import InputField from "components/Core/Inputs/Input";
import VideoPlayer from "components/Core/VideoPlayer";
import Modal from "components/Core/Modal";
import MultiSelect, { OptionType } from "components/Core/Inputs/MultiSelect";
import {
  addUpdateVideo,
  getDoctorsDropdown,
  getMuxVideoLink,
  getTags,
  getVideoSeries,
  getVideoUploadUrl,
  getVideos,
} from "api/api";
import VALIDATION from "helpers/validation_schemas";
import * as UpChunk from "@mux/upchunk";
import CircularProgress from "components/Core/CircularProgress";
import { UPLOAD_DIR } from "enums/common";
import { MEDICAL_DESIGNATIONS } from "enums/dropdown";
import errors from "enums/errors";
import DateTimePicker from "components/Core/DateTimePicker";
import moment from "moment";
import { useCurrentUser } from "context/user";
import { useDialog } from "context/dialog";
import { uploadImage } from "helpers/image";
import HOLIDAYS from "enums/holidays";
import AddDoctor from "./AddDoctor";

const MUX_BASE_URL = "https://stream.mux.com";

const seriesFilter = createFilterOptions<any>();

const Video = () => {
  const { id } = useParams();
  const { alertSuccess, alertError, showPageLoading } = useDialog();
  const { params } = useQuery();
  const isNew = params.new === "true";

  const [video, setVideo] = useState<any>();
  const [tagOptions, setTagOptions] = useState<OptionType[]>([]);
  const [videoSeries, setVideoSeries] = useState<OptionType[]>([]);

  const [launchPlayer, setLaunchPlayer] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [playerLink, setPlayerLink] = useState("");
  const [isShort, setIsShort] = useState(false);

  const [videoFile, setVideoFile] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  const [showLinkForLong, setShowLinkForLong] = useState(true);
  const [showLinkForShort, setShowLinkForShort] = useState(false);
  const [showScheduleVideoPicker, setShowScheduleVideoPicker] = useState(false);

  const [doctorOptions, setDoctorOptions] = useState<any>([]);

  const [progress, setProgress] = useState(0);

  const { user } = useCurrentUser();

  const getDoctors = async () => {
    try {
      const doctorsRes: any = await getDoctorsDropdown();
      setDoctorOptions(
        (doctorsRes?.doctors ?? []).map((dr: any) => {
          const md = dr?.medical_designation ? ", " + dr?.medical_designation : "";
          const drName = [dr.first_name ?? "", dr?.last_name ?? ""].join(" ");
          return {
            id: dr.id,
            name: drName + md,
          };
        }),
      );
    } catch (err) {
      //
    }
  };

  const getData = useCallback(async () => {
    showPageLoading(true);
    try {
      await getDoctors();

      const promiseArr = [];
      if (user?.account.id) {
        promiseArr.push(getTags(user?.account.id, 1000, 0), getVideoSeries());
      }

      if (id && !isNew) {
        promiseArr.push(getVideos(id));
      }
      const [tagData, videoSeriesRes, videoData]: any = await Promise.all(promiseArr);

      if (tagData)
        setTagOptions(
          tagData.rows.map((row: TagType) => ({ label: row.tag, value: row.id })),
        );

      if (videoSeriesRes) {
        setVideoSeries(
          videoSeriesRes.videoSeries.map((row: any) => ({
            label: row.name,
            value: row.id,
          })),
        );
      }

      if (videoData) {
        setVideo(videoData.rows[0]);
      }
    } catch (err) {
      console.log(err);
    }
    showPageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew, user]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleUpload = async () => {
    if (!id) return;
    try {
      await VALIDATION.video_file_upload.validate({
        file: videoFile,
      });
    } catch (err: any) {
      alertError(err.message);
      return;
    }

    try {
      setIsVideoLoading(true);
      const data: any = await getVideoUploadUrl(
        id,
        isShort ? "short_video" : "long_video",
      );

      const upload = UpChunk.createUpload({
        endpoint: data.url, // Authenticated url
        file: videoFile, // File object with your video file’s properties
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      // Subscribe to events
      upload.on("error", (error) => {
        alertError(error.detail);
      });

      upload.on("progress", (progress) => {
        setProgress(progress.detail);
      });

      upload.on("success", async () => {
        const videoDetail: any = await getMuxVideoLink(data.id);
        let fileName;
        if (videoDetail.max_stored_resolution === "HD") {
          fileName = "high.mp4";
        } else {
          fileName = "low.mp4";
        }

        const link = `${MUX_BASE_URL}/${videoDetail.playback_ids[0].id}/${fileName}`;
        if (isShort) {
          formik.setFieldValue("shortVideoLink", link);
          setShowLinkForShort(true);
        } else {
          formik.setFieldValue("longVideoLink", link);
          setShowLinkForLong(true);
        }
        setIsVideoLoading(false);
        setShowUploadModal(false);
        setProgress(0);
      });
    } catch (error: any) {
      console.log(error);
      alertError(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: video?.title || "",
      description: video?.description || "",
      tags: video?.tags.map((t: any) => t.id) || [],
      mds: video?.mds || [],
      shortVideoLink: video?.short_video_link || "",
      longVideoTitle: video?.long_video_title || "",
      longVideoLink: video?.video_link || "",
      doctorName: video?.doctor_name || "",
      series:
        videoSeries.find((option: any) => option.value === video?.series_id) || null,
      scheduledOn: video?.scheduled_on || null,
      makeActiveOn: video?.make_active_on || null,
      shortVideoMessage: video?.short_video_message || "",
      longVideoMessage: video?.long_video_message || "",

      thumbnail: video?.thumbnail || "",
      thumbnailFile: undefined,

      sendOnly: video?.send_only || false,

      authors: (video?.authors ?? []).length
        ? video?.authors.map(({ id }: any) => id)
        : [],
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.video_schema,
    onSubmit: async (values) => {
      if (!id) return;

      setIsLoading(true);
      try {
        let thumbnail = "";
        if (values.thumbnailFile) {
          thumbnail = await uploadImage(values.thumbnailFile, id, UPLOAD_DIR.THUMBNAIL);
        } else {
          thumbnail = formik.values.thumbnail;
        }

        let series = "";
        if (typeof values.series === "string") {
          series = values.series;
        } else {
          series = values.series?.value;
        }

        const authors: string[] = values.authors;

        await addUpdateVideo(id, {
          title: values.title,
          description: values.description,
          tags: values.tags,
          mds: values.mds,
          short_video_link: values.shortVideoLink,
          long_video_title: values.longVideoTitle,
          video_link: values.longVideoLink,
          thumbnail: thumbnail,
          doctor_name: values.doctorName,
          series,
          scheduled_on: values.scheduledOn,
          make_active_on: values.makeActiveOn,
          short_video_message: values.shortVideoMessage || null,
          long_video_message: values.longVideoMessage || null,
          send_only: values.sendOnly,
          authors: (doctorOptions ?? []).filter(({ id }: any) => authors.includes(id)),
        });

        // remove query params
        window.history.pushState({}, document.title, window.location.pathname);

        alertSuccess("Video updated successfully");
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      }

      setIsLoading(false);
    },
  });

  const handleSeriesChange = (event: any, newValue: any) => {
    if (typeof newValue === "string") {
      formik.setFieldValue("series", newValue);
    } else if (newValue && newValue.inputValue) {
      formik.setFieldValue("series", newValue.inputValue);
    } else {
      formik.setFieldValue("series", newValue);
    }

    if (!newValue) {
      formik.setFieldError("series", "series is a required field");
    }
  };

  const filterSeries = (options: any[], params: any) => {
    const filtered = seriesFilter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.label);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        label: `Add "${inputValue}" Series`,
      });
    }
    return filtered;
  };

  console.log(formik.values.authors);

  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <VideoPlayer
        link={playerLink}
        openPlayer={launchPlayer}
        close={() => setLaunchPlayer(false)}
      />

      <Modal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        width={"600px"}
      >
        <FileUpload
          id="video"
          accept="video"
          hoverLabel="Click or drag file here and click upload button"
          dropLabel="Drop file here and click upload button"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target?.files?.length > 0) {
              setVideoFile(event.target.files[0]);
            }
          }}
          onDrop={(event: React.DragEvent<HTMLElement>) => {
            setVideoFile(event.dataTransfer.files[0]);
          }}
          imageButton
        />
        <Typography align="center" sx={{ opacity: "0.5" }}>
          {"Allowed *.mp4, *.m4v, *.avi, *.mov"}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <LoadingButton
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            loading={isVideoLoading}
            onClick={handleUpload}
            loadingIndicator={<CircularProgress variant="determinate" value={progress} />}
          >
            Upload
          </LoadingButton>
        </Box>
      </Modal>

      <Card sx={{ p: 5 }}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              label="Title"
              id="title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              fullWidth
            />

            <InputField
              label="Description"
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
            />

            <Box>
              <Stack direction="row" spacing={10} alignItems="center" mb={1}>
                <InputLabel
                  error={
                    formik.touched.shortVideoLink && Boolean(formik.errors.shortVideoLink)
                  }
                >
                  Short Video
                </InputLabel>
                <Stack direction="row" alignItems="center">
                  <Typography>Video</Typography>
                  <Switch
                    checked={showLinkForShort}
                    onChange={() => setShowLinkForShort(!showLinkForShort)}
                  />
                  <Typography>Link</Typography>
                </Stack>
                {formik.values.shortVideoLink && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setPlayerLink(formik.values.shortVideoLink);
                      setLaunchPlayer(true);
                    }}
                  >
                    View Video
                  </Button>
                )}
              </Stack>

              {showLinkForShort ? (
                <InputField
                  id="shortVideoLink"
                  name="shortVideoLink"
                  value={formik.values.shortVideoLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.shortVideoLink && Boolean(formik.errors.shortVideoLink)
                  }
                  helperText={
                    formik.touched.shortVideoLink && formik.errors.shortVideoLink
                  }
                  fullWidth
                />
              ) : (
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsShort(true);
                      setShowUploadModal(true);
                    }}
                  >
                    Upload Short Video
                  </Button>
                  <FormHelperText
                    error={
                      formik.touched.shortVideoLink &&
                      Boolean(formik.errors.shortVideoLink)
                    }
                  >
                    {formik.touched.shortVideoLink && formik.errors.shortVideoLink}
                  </FormHelperText>
                </Stack>
              )}
            </Box>

            <InputField
              label="Message For Short Video"
              id="shortVideoMessage"
              name="shortVideoMessage"
              value={formik.values.shortVideoMessage}
              placeholder="Check out video from {{ACCOUNT_NAME}} of {{DR_NAME}} presenting the {{VIDEO_SERIES}} data. {{LINK}}"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.shortVideoMessage &&
                Boolean(formik.errors.shortVideoMessage)
              }
              helperText={
                formik.touched.shortVideoMessage && formik.errors.shortVideoMessage
              }
              fullWidth
            />

            <InputField
              label="Long Video Title"
              id="longVideoTitle"
              name="longVideoTitle"
              value={formik.values.longVideoTitle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.longVideoTitle && Boolean(formik.errors.longVideoTitle)
              }
              helperText={formik.touched.longVideoTitle && formik.errors.longVideoTitle}
              fullWidth
            />

            <Box>
              <Stack direction="row" spacing={10} alignItems="center" mb={1}>
                <InputLabel
                  error={
                    formik.touched.longVideoLink && Boolean(formik.errors.longVideoLink)
                  }
                >
                  Long Video
                </InputLabel>
                <Stack direction="row" alignItems="center">
                  <Typography>Video</Typography>
                  <Switch
                    checked={showLinkForLong}
                    onChange={() => setShowLinkForLong(!showLinkForLong)}
                  />
                  <Typography>Link</Typography>
                </Stack>
                {formik.values.longVideoLink && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setPlayerLink(formik.values.longVideoLink);
                      setLaunchPlayer(true);
                    }}
                  >
                    View Video
                  </Button>
                )}
              </Stack>

              {showLinkForLong ? (
                <InputField
                  id="longVideoLink"
                  name="longVideoLink"
                  value={formik.values.longVideoLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.longVideoLink && Boolean(formik.errors.longVideoLink)
                  }
                  helperText={formik.touched.longVideoLink && formik.errors.longVideoLink}
                  fullWidth
                />
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsShort(false);
                    setShowUploadModal(true);
                  }}
                >
                  Upload Long Video
                </Button>
              )}
            </Box>

            <InputField
              label="Message For Long Video"
              id="longVideoMessage"
              name="longVideoMessage"
              placeholder="Click here to view full length video: {{LINK}}"
              value={formik.values.longVideoMessage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.longVideoMessage && Boolean(formik.errors.longVideoMessage)
              }
              helperText={
                formik.touched.longVideoMessage ? formik.errors.longVideoMessage : ""
              }
              fullWidth
            />

            <Box>
              <InputLabel>Tags</InputLabel>
              <MultiSelect
                id={"select-tags"}
                labelId={"select-tags-label"}
                options={tagOptions}
                selectValue={formik.values.tags}
                setSelectValue={(value) => {
                  formik.setFieldValue("tags", value);
                }}
                placeholder={"Select tags"}
                error={formik.touched.tags && Boolean(formik.errors.tags)}
                helperText={formik.errors.tags && formik.errors.tags}
              />
            </Box>

            <Box>
              <InputLabel>Target Audience</InputLabel>
              <MultiSelect
                id={"select-mds"}
                labelId={"select-mds-label"}
                options={MEDICAL_DESIGNATIONS}
                selectValue={formik.values.mds}
                setSelectValue={(value) => {
                  formik.setFieldValue("mds", value);
                }}
                onBlur={(event) => {
                  if (event.target.value && event.target.value.length === 0) {
                    formik.setErrors({ mds: errors.VALIDATION.MDS.REQUIRED });
                  }
                }}
                placeholder={"Select Target Audience"}
                error={formik.touched.mds && Boolean(formik.errors.mds)}
                helperText={formik.errors.mds && formik.errors.mds}
              />
            </Box>

            <FileUpload
              id="thumbnail"
              label="Thumbnail"
              name="thumbnail"
              accept="image"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files !== null && event.target?.files?.length > 0) {
                  formik.setFieldValue("thumbnailFile", event.target.files[0]);
                  const size = event.target.files[0].size;
                  if (size / 1024 <= 5 * 1024) {
                    formik.setErrors({
                      thumbnailFile: errors.VALIDATION.THUMBNAIL_FILE.SIZE,
                    });
                  }
                }
              }}
              onDrop={(event: React.DragEvent<HTMLElement>) => {
                formik.setFieldValue("thumbnailFile", event.dataTransfer.files[0]);
                const size = event.dataTransfer.files[0].size;
                if (size / 1024 <= 5 * 1024) {
                  formik.setErrors({
                    thumbnailFile: errors.VALIDATION.THUMBNAIL_FILE.SIZE,
                  });
                }
              }}
              imageButton
              image={{
                url: formik.values.thumbnail,
              }}
              error={formik.touched.thumbnailFile && Boolean(formik.errors.thumbnailFile)}
              helperText={formik.touched.thumbnailFile && formik.errors.thumbnailFile}
            />

            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <MultiSelect
                id={"doctors"}
                labelId={"doctors"}
                label={"Doctors"}
                options={doctorOptions.map((dr: any) => ({
                  label: dr.name,
                  value: dr.id,
                }))}
                selectValue={formik.values.authors}
                setSelectValue={(values) => {
                  formik.setFieldValue("authors", values);
                }}
                placeholder="Select Doctors"
                showChips={false}
                sortSelected={false}
                error={formik.touched.authors && Boolean(formik.errors.authors)}
                helperText={formik.touched.authors && formik.errors.authors}
              />
              <AddDoctor
                refreshDoctors={(doctorId: string) => {
                  getDoctors().then(() => {
                    const authors = formik.values.authors ?? [];
                    formik.setFieldValue("authors", [...authors, doctorId]);
                  });
                }}
              />
            </Stack>

            {/* <InputField
              label="Doctor Name"
              id="doctorName"
              name="doctorName"
              value={formik.values.doctorName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.doctorName && Boolean(formik.errors.doctorName)}
              helperText={formik.touched.doctorName && formik.errors.doctorName}
              fullWidth
            /> */}

            <Box>
              <InputLabel>Video Series</InputLabel>
              <Autocomplete
                id="video-series-dropdown"
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={videoSeries}
                value={formik.values.series}
                onChange={handleSeriesChange}
                filterOptions={filterSeries}
                getOptionLabel={(option: any) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.label;
                }}
                renderOption={(props, option) => (
                  <li key={"series-option-" + option.id} {...props}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <InputField
                    {...params}
                    error={formik.touched.series && Boolean(formik.errors.series)}
                    helperText={
                      formik.touched.series && <>{formik.errors.series || ""}</>
                    }
                  />
                )}
              />
            </Box>

            <div>
              {showScheduleVideoPicker ? (
                <DateTimePicker
                  label="Schedule Video"
                  orientation="landscape"
                  ampm={false}
                  value={formik.values.scheduledOn && moment(formik.values.scheduledOn)}
                  onChange={(date) => {
                    formik.setFieldValue("scheduledOn", date);
                    formik.setFieldValue("makeActiveOn", null);
                  }}
                  error={formik.touched.scheduledOn && Boolean(formik.errors.scheduledOn)}
                  helperText={formik.touched.scheduledOn && formik.errors.scheduledOn}
                  tooltip="When scheduling a video, this will send the video to all current subscribers at the set date time. After it is sent, the first time, the video will go back to the normal schedule of the video"
                  disablePast
                  shouldDisableDate={(date: any) => {
                    const h: any = HOLIDAYS;
                    const holidays = h[date.year()];
                    return (
                      [0, 6].includes(date.day()) ||
                      holidays?.includes(date.format("YYYY-MM-DD"))
                    );
                  }}
                />
              ) : (
                <div>
                  <label style={{ color: "gray" }}>Schedule Video</label>
                  <p>
                    {formik.values.scheduledOn &&
                      moment(formik.values.scheduledOn).format("MM/DD/YYYY")}
                  </p>
                </div>
              )}
              <Button
                variant="contained"
                onClick={() => setShowScheduleVideoPicker(!showScheduleVideoPicker)}
              >
                {showScheduleVideoPicker ? "Cancel" : "New Schedule"}
              </Button>
            </div>

            <DateTimePicker
              label="Make Video Active"
              orientation="landscape"
              ampm={false}
              value={formik.values.makeActiveOn && moment(formik.values.makeActiveOn)}
              onChange={(date) => {
                formik.setFieldValue("makeActiveOn", date);
                formik.setFieldValue("scheduledOn", null);
              }}
              error={formik.touched.makeActiveOn && Boolean(formik.errors.makeActiveOn)}
              helperText={formik.touched.makeActiveOn && formik.errors.makeActiveOn}
            />

            <FormControlLabel
              id="sendOnly"
              name="sendOnly"
              label="Don't show on suggestion list"
              control={<Checkbox checked={formik.values.sendOnly} />}
              value={formik.values.sendOnly}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <Box textAlign={"right"}>
              <LoadingButton
                type="submit"
                size="large"
                variant="contained"
                loading={isLoading}
              >
                Save Video
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Card>
    </Container>
  );
};

export default Video;
