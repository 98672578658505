import { getHeader } from "helpers/utils";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

const fetchData = async (url: string, query: string, variables: any = {}) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: getHeader().headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    if (!response.ok) {
      const result = await response.json();
      console.log("error", result);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error: any) {
    console.error("Error fetching GraphQL data:", error);
    throw new Error("Failed to fetch data");
  }
};

export const getUsers = async (filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/graphql`;

  const query = `
    query GetUsers($page: Int!, $limit: Int!) {
      getUsers(page: $page, limit: $limit) {
        rows {
          id
          first_name
          last_name
          email
          mobile
          email
          account_id
          is_super_admin
          status
          created_at
          updated_at
          receive_email_notifications
          admin_role {
            id
            role {
          name
        }
         }}
        count
      }
    }
  `;

  const variables = {
    page: page || 0,
    limit: limit || 20,
    filter: filter || {},
  };

  try {
    const result = await fetchData(url, query, variables);

    // created_at from graphql is unixTimestamp, Convert created_at to ISO 8601 format
    const users = result.data.getUsers.rows.map((user: any) => {
      return {
        ...user,
        created_at: moment(user.created_at).isValid()
          ? moment(user.created_at).toISOString()
          : moment(user.created_at, "x").toISOString(),
      };
    });

    return {
      ...result.data.getUsers,
      rows: users,
    };
  } catch (error) {
    console.error("Error fetching users:", error);
    return { error: "Failed to fetch users" };
  }
};

export const getProfile = async () => {
  let url = `${API_URL}/graphql`;

  const query = `query {
  getProfile {
    id
    first_name
    last_name
    email
    mobile
    account_id
    is_super_admin
    status
    created_at
    updated_at
    receive_email_notifications
    admin_role {
      id
      role {
        name
      }
    }
  }
}`;

  try {
    const result = await fetchData(url, query);

    return result.data.getProfile;
  } catch (error) {
    console.error("Error fetching userProfile:", error);
    return { error: "Failed to fetch userProfile" };
  }
};

export const inviteUser = async (email: string, role: string) => {
  let url = `${API_URL}/graphql`;

  const variables = {
    email,
    role,
  };

  const query = `
    mutation InviteUser($email: String!, $role: String!) {
      inviteUser(input: {
        email: $email,
        role: $role
      }) {
        message
        link
      }
    }
  `;

  try {
    const result = await fetchData(url, query, variables);
    return result.data;
  } catch (error) {
    console.error("Error inviting user:", error);
    throw new Error("Failed to invite user");
  }
};

export const upsertUser = async (input: any) => {
  let url = `${API_URL}/graphql`;

  const variables = {
    firstName: input.firstName,
    lastName: input.lastName,
    email: input.email,
    mobile: input.mobile,
    receiveEmailNotifications: input.receiveEmailNotifications,
  };

  const query = `
    mutation UpsertUser($firstName:String!, $lastName:String!, $email:String!, $mobile:String!, $receiveEmailNotifications:Boolean!) {
        upsertUser(input: {
            firstName: $firstName,
            lastName: $lastName,
            email: $email,
            mobile: $mobile,
            receiveEmailNotifications: $receiveEmailNotifications
      }) {
    data
  }
}`;

  try {
    const result = await fetchData(url, query, variables);
    return result.data;
  } catch (error) {
    console.error("Error upserting user:", error);
    throw new Error("Failed to upsert user");
  }
};

export const updateUserRole = async (userId: string, roleName: string) => {
  let url = `${API_URL}/graphql`;

  const variables = {
    id: userId,
    roleName,
  };

  const query = `
      mutation UpdateUserRole($id: String!, $roleName: String!) {
        updateUserRole(input: {
        id: $id,
        roleName: $roleName,
        }) {
      data
    }
  }`;

  try {
    const result = await fetchData(url, query, variables);
    return result.data;
  } catch (error) {
    console.error("Error update user:", error);
    throw new Error("Failed to update userRole");
  }
};
