import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Container, Stack, Typography } from "@mui/material";
import { changePassword, refreshToken } from "helpers/auth";
import { useCurrentUser } from "context/user";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Tab, TabContext, TabList, TabPanel } from "components/Core/Tab/Tab";
import useQuery from "hooks/useQuery";
import { useFormik } from "formik";
import VALIDATION from "helpers/validation_schemas";
import { useDialog } from "context/dialog";
import InputField from "components/Core/Inputs/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// import {getUser} from "api/api";
import { getProfile as gqlGetProfile } from "api/graphql";
// import { upsertUser } from "api/api";
import { upsertUser as gqlUpsertUser } from "api/graphql";

const Profile = () => {
  const { user } = useCurrentUser();
  const { alertSuccess, alertError } = useDialog();

  const [isProfileLoading, setProfileLoading] = useState(false);
  const [isPasswordLoading, setPasswordLoading] = useState(false);

  const [isReceiveEmail, setReceiveEmail] = useState(false);
  const [isAccountAdmin, setIsAccountAdmin] = useState(false);

  const [tabValue, setTabValue] = useState("1");
  const { hash, updateUri } = useQuery();

  //get user account details, whether account admin, whether receive email, need api
  //get whether account admin api, utils/getuser is from congito
  //get single account details api, getusers is for all users

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    let hashVal = "";
    switch (newValue) {
      case "2":
        hashVal = "#password";
        break;
    }
    updateUri(hashVal);
    setTabValue(newValue);
  };

  useEffect(() => {
    gqlGetProfile().then((userDetails) => {
      //@ts-ignore  check if user is account admin
      setIsAccountAdmin(userDetails?.admin_role?.role?.name === "account_admin");
      //@ts-ignore  check if user receive email
      setReceiveEmail(userDetails.receive_email_notifications);
    });
  }, []);

  useEffect(() => {
    switch (hash) {
      case "#password":
        setTabValue("2");
        break;
      default:
        setTabValue("1");
        break;
    }
  }, [hash]);

  const profileFormik = useFormik({
    initialValues: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      email: user?.email || "",
      mobile: user?.phone_number || "",
      receiveEmailNotifications: isReceiveEmail,
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.profile_update,
    onSubmit: async (values) => {
      setProfileLoading(true);
      try {
        await gqlUpsertUser(values);
        await refreshToken();
        alertSuccess("Profile updated successfully");
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      }

      setProfileLoading(false);
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: VALIDATION.password_update,
    onSubmit: async (values, { resetForm }) => {
      setPasswordLoading(true);

      try {
        await changePassword(values.currentPassword, values.newPassword);
        alertSuccess("Password updated successfully");
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      }
      resetForm();
      setPasswordLoading(false);
    },
  });

  return (
    <Container>
      <Typography variant="h4" mb={5}>
        Profile
      </Typography>
      <Box>
        <TabContext value={tabValue}>
          <Box>
            <TabList onChange={handleChange}>
              <Tab
                icon={<AccountBoxIcon />}
                iconPosition="start"
                label="General"
                value="1"
              />
              <Tab
                icon={<VpnKeyIcon />}
                iconPosition="start"
                label="Change Password"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Card>
              <CardContent>
                <Box component="form" onSubmit={profileFormik.handleSubmit}>
                  <Stack gap={2}>
                    <InputField
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      value={profileFormik.values.firstName}
                      onChange={profileFormik.handleChange}
                      onBlur={profileFormik.handleBlur}
                      error={
                        profileFormik.touched.firstName &&
                        !!profileFormik.errors.firstName
                      }
                      helperText={
                        profileFormik.touched.firstName && profileFormik.errors.firstName
                      }
                      labelInsideInputField
                    />

                    <InputField
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={profileFormik.values.lastName}
                      onChange={profileFormik.handleChange}
                      onBlur={profileFormik.handleBlur}
                      error={
                        profileFormik.touched.lastName && !!profileFormik.errors.lastName
                      }
                      helperText={
                        profileFormik.touched.lastName && profileFormik.errors.lastName
                      }
                      labelInsideInputField
                    />

                    <InputField
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      value={profileFormik.values.email}
                      onChange={profileFormik.handleChange}
                      onBlur={profileFormik.handleBlur}
                      error={profileFormik.touched.email && !!profileFormik.errors.email}
                      helperText={
                        profileFormik.touched.email && profileFormik.errors.email
                      }
                      disabled
                      labelInsideInputField
                    />

                    <InputField
                      fullWidth
                      id="mobile"
                      label="Phone Number"
                      name="mobile"
                      value={profileFormik.values.mobile}
                      onChange={profileFormik.handleChange}
                      onBlur={profileFormik.handleBlur}
                      error={
                        profileFormik.touched.mobile && !!profileFormik.errors.mobile
                      }
                      helperText={
                        profileFormik.touched.mobile && profileFormik.errors.mobile
                      }
                      labelInsideInputField
                    />

                    {(isAccountAdmin || user?.isSuperAdmin) && (
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          checked={profileFormik.values.receiveEmailNotifications}
                          id="receiveEmailNotifications"
                          name="receiveEmailNotifications"
                          label="Receive Email Notifications"
                          onChange={profileFormik.handleChange}
                        />
                      </FormGroup>
                    )}

                    <Box>
                      <LoadingButton
                        variant="contained"
                        loading={isProfileLoading}
                        type="submit"
                        size="large"
                      >
                        Save Changes
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value="2">
            <Card>
              <CardContent>
                <Box component="form" onSubmit={passwordFormik.handleSubmit}>
                  <Stack gap={2}>
                    <InputField
                      fullWidth
                      type="password"
                      id="currentPassword"
                      label="Current Password"
                      name="currentPassword"
                      value={passwordFormik.values.currentPassword}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                      error={
                        passwordFormik.touched.currentPassword &&
                        !!passwordFormik.errors.currentPassword
                      }
                      helperText={
                        passwordFormik.touched.currentPassword &&
                        passwordFormik.errors.currentPassword
                      }
                      labelInsideInputField
                    />

                    <InputField
                      fullWidth
                      type="password"
                      id="newPassword"
                      label="New Password"
                      name="newPassword"
                      value={passwordFormik.values.newPassword}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                      error={
                        passwordFormik.touched.newPassword &&
                        !!passwordFormik.errors.newPassword
                      }
                      helperText={
                        passwordFormik.touched.newPassword &&
                        passwordFormik.errors.newPassword
                      }
                      labelInsideInputField
                    />

                    <InputField
                      fullWidth
                      type="password"
                      id="confirmPassword"
                      label="Confirm New Password"
                      name="confirmPassword"
                      value={passwordFormik.values.confirmPassword}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                      error={
                        passwordFormik.touched.confirmPassword &&
                        !!passwordFormik.errors.confirmPassword
                      }
                      helperText={
                        passwordFormik.touched.confirmPassword &&
                        passwordFormik.errors.confirmPassword
                      }
                      labelInsideInputField
                    />

                    <Box>
                      <LoadingButton
                        variant="contained"
                        loading={isPasswordLoading}
                        type="submit"
                        size="large"
                      >
                        Save Changes
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
};

export default Profile;
